import planMixin from "../mixin/plan";
import pricingMixin from "../mixin/pricing";
Vue.component("pricing", {
    mixins: [planMixin, pricingMixin],
    props: {
        target: {
            type: String,
            default: false,
        },
        connected: {
            type: Boolean,
            default: false,
        },
        initNbUser: {
            type: Number,
            required: true,
            default: false,
        },
    },
    data: () => ({
        freePricing: {
            id: "freePricing",
            title: "FREE",
            free: true,
            suitedFor: "The best plan for small gatherings",
            cost: 0,
            userUnit: " ",
            color: "blue",
        },
        eventOneDayPricing: {
            id: "eventOneDayPricing",
            title: "1 DAY",
            free: false,
            suitedFor: "The best plan for meetups",
            cost: 4,
            userUnit: "simultaneous user",
            color: "green",
        },
        eventsPricing: {
            id: "eventsPricing",
            title: "5 DAYS",
            free: false,
            suitedFor: "The best plan for big workshops & large events",
            cost: 8,
            userUnit: "simultaneous user",
            color: "green",
        },
        virtualOfficePricing: {
            id: "virtualOfficePricing",
            title: "MONTHLY",
            free: false,
            renewable: true,
            suitedFor: "The best plan for remote & hybrid teams",
            cost: 10,
            userUnit: "simultaneous user",
            color: "green",
        },
        hasChange: false,
    }),
    mounted() {
        this.nbUser = this.initNbUser;

        let element = document.getElementById("nbUser");
        element.addEventListener(
            "blur",
            (event) => {
                if (
                    event.target.value === null ||
                    event.target.value === undefined ||
                    event.target.value === "" ||
                    event.target.value < 0
                ) {
                    this.nbUser = 1;
                }
                if (this.nbUser === null || this.nbUser === undefined || this.nbUser === "" || this.nbUser < 0) {
                    this.nbUser = 1;
                }
            },
            { passive: true }
        );
        let pricingFree = document.getElementById("pricing-table-card-footer-free");
        let pricingEvent = document.getElementById("pricing-table-card-footer-event");
        let pricingVo = document.getElementById("pricing-table-card-footer-vo");
        setTimeout(() => {
            this.moveBodyCard(pricingFree);
            this.moveBodyCard(pricingEvent);
            this.moveBodyCard(pricingVo);
        }, 0);
        document.addEventListener(
            "scroll",
            () => {
                this.moveBodyCard(pricingFree);
                this.moveBodyCard(pricingEvent);
                this.moveBodyCard(pricingVo);
            },
            { passive: true }
        );
        window.addEventListener(
            "resize",
            () => {
                this.moveBodyCard(pricingFree);
                this.moveBodyCard(pricingEvent);
                this.moveBodyCard(pricingVo);
            },
            { passive: true }
        );
        this.getPlans();
    },
    computed: {
        tables() {
            const tablesReturn = [];
            if (!this.connected) {
                tablesReturn.push(this.freePricing);
            }
            /*if(this.target === 'eventsPricing'){
                tablesReturn.push(this.eventOneDayPricing);
                tablesReturn.push(this.eventsPricing);
                tablesReturn.push(this.virtualOfficePricing);
            }else{
                tablesReturn.push(this.virtualOfficePricing);
                tablesReturn.push(this.eventOneDayPricing);
                tablesReturn.push(this.eventsPricing);
            }*/
            tablesReturn.push(this.eventOneDayPricing);
            tablesReturn.push(this.eventsPricing);
            tablesReturn.push(this.virtualOfficePricing);
            return tablesReturn;
        },
        virtualOfficePlan() {
            return this.plans.find((c) => c.attributes.type === "virtual_office");
        },
        virtualOfficeYearlyPlan() {
            return this.plans.find((c) => c.attributes.type === "virtual_office_yearly");
        },
        eventPlan() {
            return this.plans.find((c) => c.attributes.type === "event");
        },
        eventOneDayPlan() {
            return this.plans.find((c) => c.attributes.type === "event_one_day");
        },
    },
    methods: {
        moveBodyCard(pricing) {
            if (!pricing) {
                return;
            }
            if (this.detectMob()) {
                pricing.parentElement.style.paddingBottom = "0";
                pricing.style.position = "relative";
                pricing.style.bottom = "0";
                return;
            } else {
                pricing.parentElement.style.paddingBottom = "20px";
                pricing.style.position = "absolute";
            }
            if (
                pricing.parentElement.getBoundingClientRect().bottom + pricing.getBoundingClientRect().height >
                window.innerHeight
            ) {
                pricing.style.bottom = `${pricing.parentElement.getBoundingClientRect().bottom - window.innerHeight}px`;
            } else {
                pricing.style.bottom = "-" + pricing.getBoundingClientRect().height + "px";
            }
            if (
                pricing.parentElement.getBoundingClientRect().top + 50 + pricing.getBoundingClientRect().height >
                window.innerHeight
            ) {
                pricing.style.bottom = 480 - pricing.getBoundingClientRect().height + "px";
            }
        },
        detectMob() {
            return window.innerWidth <= 800 || window.innerHeight <= 600;
        },
        subscribeVirtualOffice() {
            let planIdToSubscribe = this.virtualOfficePlan.id;
            if (this.checkedPricingType) {
                planIdToSubscribe = this.virtualOfficeYearlyPlan.id;
            }
            window.location = `/checkout?plan=${planIdToSubscribe}&quantity=${this.nbUser}`;
        },
        subscribeEvent() {
            window.location = `/checkout?plan=${this.eventPlan.id}&quantity=${this.nbUser}`;
        },
        subscribeEventOneDay() {
            window.location = `/checkout?plan=${this.eventOneDayPlan.id}&quantity=${this.nbUser}`;
        },
        //Scroll view on pricing when is updated by the user
        nbUserChange() {
            if (!this.hasChange) {
                const inputElement = document.getElementById("nbUser");
                inputElement.scrollIntoView();
                this.hasChange = true;
            }
        },
    },
});
