Vue.component("map-card", {
    props: {
        selectedroom: {
            type: Object,
            default: null,
        },
        mapUrl: String,
    },
    data() {
        return {
            name: "",
        };
    },
    methods: {
        //TODO refactor
        selectMap() {
            if (this.$parent && this.$parent.$parent && this.$parent.$parent.selectedRoom) {
                this.$parent.$parent.selectedRoom.mapChose = {
                    name: this.name,
                    mapUrl: this.mapUrl,
                };
                $("#modal-show-map-selected").modal("hide");
                $("#editWorldModal").modal("show");
            } else {
                window.location = `?mapUrl=${this.mapUrl}`;
            }
        },
        getMapName(mapName) {
            this.name = mapName;
        },
    },
});
