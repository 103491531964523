Vue.component("headbands", {
    data: () => ({
        headbands: null,
    }),
    mounted() {
        this.get();
    },
    methods: {
        get() {
            return axios
                .get("/kiosk/headband/published", {})
                .then((response) => {
                    if (response.status > 300) {
                        throw Error("No data");
                    }
                    this.headbands = response.data;
                    return response;
                })
                .catch((err) => {
                    console.error("Error to get headband", err);
                    this.headbands = null;
                    throw err;
                });
        },
    },
});
