Vue.component("contact-form", {
    props: {
        toName: {
            type: String,
        },
        toEmail: {
            type: String,
        },
        object: {
            type: String,
        },
    },
    data: () => ({
        form: new SparkForm({
            toName: null,
            toEmail: null,
            fromName: null,
            fromEmail: null,
            object: null,
            message: null,
        }),
        emailSent: false,
    }),
    created() {
        this.form.toName = this.toName;
        this.form.toEmail = this.toEmail;
        this.form.object = this.object;
    },
    methods: {
        sendMail() {
            this.emailSent = false;
            return Spark.post("/email/contact", this.form)
                .then((res) => {
                    this.resetForm();
                    if (this.$bvToast) {
                        this.$bvToast.toast(`Email was sent !`, {
                            title: "Send email",
                            variant: "success",
                            autoHideDelay: 5000,
                        });
                    }
                    this.emailSent = true;
                    return res;
                })
                .catch((err) => {
                    if (this.$bvToast) {
                        this.$bvToast.toast(`Email wasn't sent. Error: ${err.message}`, {
                            title: "Send email",
                            variant: "danger",
                            autoHideDelay: 5000,
                        });
                    }
                    console.error(err);
                    throw err;
                });
        },
        resetForm() {
            this.form.reset();
            this.form.toName = this.toName;
            this.form.toEmail = this.toEmail;
            this.form.object = this.object;
        },
    },
});
