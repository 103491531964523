import base from "auth/register-stripe";
import axios from "axios";
import slugify from "slugify";
Vue.component("spark-register-stripe", {
    mixins: [base],
    props: ["mapName", "mapUrl", "NPOMode", "NPOType", "displayImage"],
    data: () => ({
        existTeamSlug: true,
        existTeamSlugLoading: false,
        displayedImage: true,
    }),
    created: function () {
        if (this.mapName) {
            this.registerForm.mapName = this.mapName;
            this.registerForm.mapNameSlug = slugify(this.registerForm.mapName).toLowerCase();
        }
        if (this.mapUrl) {
            this.registerForm.mapUrl = this.mapUrl;
        }
        if (this.mapName) {
            this.registerForm.mapName = this.mapName;
        }
        if (this.NPOType) {
            this.registerForm.mapNameSlug = slugify(this.registerForm.mapName).toLowerCase();
            this.registerForm.NPOMode = true;
            this.registerForm.NPOType = this.NPOType;
        }
        this.displayedImage = this.displayImage != undefined && this.displayImage !== "false";
        if (Spark.state.user) {
            if (Spark.state.user.name) {
                this.registerForm.name = Spark.state.user.name;
            }
            if (Spark.state.user.email) {
                this.registerForm.email = Spark.state.user.email;
            }
        }
    },
    computed: {
        computedMapUrl: function () {
            const world = (this.NPOType ? this.registerForm.organization : this.registerForm.world) || "";
            const mapName = this.registerForm.mapName || "";
            return (
                "https://play.workadventu.re/@/" +
                slugify(this.registerForm.organization).toLowerCase() +
                "/" +
                slugify(world).toLowerCase() +
                "/" +
                slugify(mapName).toLowerCase()
            );
        },
    },
    methods: {
        verifySlugTeam() {
            this.existTeamSlugLoading = true;
            let slug = slugify(this.registerForm.organization).toLowerCase();
            this.registerForm.organizationSlug = slug;
            if (!slug) {
                this.existTeamSlug = true;
                return;
            }
            return axios
                .get(`/team/slug/${slug}`)
                .then((response) => {
                    this.existTeamSlug = response.data.exist;
                    this.existTeamSlugLoading = false;
                    if (this.NPOType) {
                        this.registerForm.worldSlug = this.registerForm.organizationSlug;
                        this.registerForm.world = this.registerForm.organization;
                    }
                })
                .catch((err) => {
                    this.existTeamSlugLoading = false;
                    this.existTeamSlug = true;
                    console.error(err);
                });
        },
        slugifyWorld() {
            this.registerForm.worldSlug = slugify(this.registerForm.world).toLowerCase();
        },
        slugifyMapName() {
            this.registerForm.mapNameSlug = slugify(this.registerForm.mapName).toLowerCase();
        },
    },
});
