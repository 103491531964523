import checkMapMixin from "../mixin/checkMapUrl";
Vue.component("custom-map-card", {
    mixins: [checkMapMixin],
    data: () => ({
        mapUrl: null,
        mapUrlFormError: null,
    }),
    mounted() {
        const urlElement = document.getElementById("roomnmapurlinput");
        urlElement.addEventListener(
            "blur",
            () => {
                this.promiseToCheckMapUrl = this.checkMapUrl(this.mapUrl);
            },
            { passive: true }
        );
    },
    methods: {
        selectMap() {
            this.checkForm();
            if (this.mapUrlFormError || this.errorsMapUrl) {
                throw "error map url";
            }
            if (this.$parent && this.$parent.$parent && this.$parent.$parent.selectedRoom) {
                this.$parent.$parent.selectedRoom.mapChose = {
                    mapUrl: this.mapUrl,
                };
                $("#modal-show-map-selected").modal("hide");
                $("#editWorldModal").modal("show");
            } else {
                window.location = `?mapUrl=${this.mapUrl}`;
            }
        },
        resetFormError() {
            this.mapUrlFormError = null;
        },
        checkForm() {
            this.resetFormError();
            if (!this.mapUrl) {
                this.mapUrlFormError = "Map url is empty.";
            }
        },
    },
    watch: {
        mapUrl() {
            this.checkForm();
        },
    },
});
