const DEFAULT_MESSAGE =
    "This feature is only for our premium members. We will glad to count you among our premium members!";
Vue.component("premium-modal", {
    props: ["type"],
    data: () => ({
        message: DEFAULT_MESSAGE,
    }),
    mounted() {
        this.initMessage(this.type);
    },
    watch: {
        type(value) {
            this.initMessage(value);
        },
    },
    methods: {
        initMessage(value) {
            this.message = DEFAULT_MESSAGE;
            if (value === "disabled") {
                this.message = `<p> This feature is not available. Our development work on it, but they have only 2 hands! Damn-it! <span style="font-size:24px">&#128080;</span> </p>
                                    <p>It will deployed soon, if you have need help, contact us.</p>`;
            }
            if (value === "world") {
                this.message = "To create a new world, you must to subscribe at premium offer.";
            }
            if (value === "report") {
                this.message = "Report is available only for the private MAP. If you want create your private event.";
            }
            if (value === "member") {
                this.message =
                    "You have a public room created. If you want create a private room and add or import your members.";
            }
        },
    },
});
