export const data = () => ({
    nbUser: 15,
    newCapacity: 1,
    priceEventOneDay: 4,
    priceEvent: 8,
    priceEventOffer: 8,
    priceVO: 15,
    priceVOOfferByYear: 10,
    proration: null,
    prorationLoader: false,
    checkedPricingType: false,
});
export default {
    data,
    computed: {
        priceVOOfferForPricingSimulator() {
            if (this.checkedPricingType) {
                return this.priceVOOfferByYear;
            } else {
                return this.priceVO;
            }
        },
        priceVOOffer() {
            if (this.plan && this.plan.interval === "yearly") {
                return this.priceVOOfferByYear * 12;
            } else {
                return this.priceVO;
            }
        },
        calculateEventOneDayPricing() {
            return this.nbUser * this.priceEventOneDay;
        },
        newCalculateEventOneDayPricing() {
            return this.newCapacity * this.priceEventOneDay;
        },
        calculateEventPricing() {
            return this.nbUser * this.priceEvent;
        },
        calculateEventPricingToPay() {
            return this.nbUser * this.priceEventOffer;
        },
        newCalculateEventPricing() {
            return this.newCapacity * this.priceEvent;
        },
        newCalculateEventPricingToPay() {
            return this.newCapacity * this.priceEventOffer;
        },
        calculateVoPricing() {
            return this.nbUser * this.priceVO;
        },
        calculateVoPricingToPay() {
            return this.nbUser * this.priceVOOffer;
        },
        calculateVoPricingSimulatorToPay() {
            return this.nbUser * this.priceVOOfferForPricingSimulator * (this.checkedPricingType ? 12 : 1);
        },
        newCalculateVoPricing() {
            return this.newCapacity * this.priceVO;
        },
        newCalculateVoYearPricing() {
            return this.newCapacity * this.priceVOOfferByYear;
        },
        proratesDue() {
            if (!this.proration || this.proration.amount_due === 0) {
                return 0;
            }
            const due = (this.proration.lines.data[0].amount + this.proration.lines.data[1].amount) / 100;
            if (!this.currentDiscount) {
                return due;
            }
        },
        proratesVatDue() {
            if (!this.proration || this.proration.amount_due === 0) {
                return 0;
            }
            return (
                (this.proration.lines.data[0].tax_amounts[0].amount +
                    this.proration.lines.data[1].tax_amounts[0].amount) /
                100
            );
        },
        nextPaymentProrationDate() {
            return moment.unix(this.proration.subscription_proration_date).format("dddd, MMMM Do YYYY");
        },
    },
    methods: {
        calculateProration() {
            this.prorationLoader = true;
            axios
                .get(
                    `/settings/${Spark.teamsPrefix}/${this.team.id}/subscription/${this.teamSubscribeId}/proration/${this.newCapacity}`
                )
                .then((response) => {
                    this.proration = response.data;
                    this.prorationLoader = false;
                })
                .catch((err) => {
                    console.error(err);
                    this.prorationLoader = false;
                });
        },
    },
};
