export const data = () => ({
    plans: [],
});
const moduleExports = {
    data,
    methods: {
        getPlans() {
            return axios.get("/spark/plans").then((response) => {
                this.plans = _.filter(response.data, { type: "team" });
            });
        },
        getPlanById(id) {
            return this.plans.find((c) => c.id === id);
        },
    },
};
export default moduleExports;
export const methods = moduleExports.methods;
